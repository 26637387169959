.view-button {
    width: 130px;
}

.price-container {
    margin-top: 60px;
}

.main-container {
    padding-bottom: 20px;
    border-bottom: 1px solid #aaa;
}