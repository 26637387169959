.Upcoming-property {
    font-size: 18px;
}

.Upcoming-property2 {
    text-decoration: none;
    color: #007bff;
}

.content2-head {
    font-size: 24px;
    font-weight: 550;
    margin-bottom: 20px;
    color: #373737;

}

.content2-border {
    height: 1px;
    background-color: black;
    margin-bottom: 15px;
}

.time {
    font-size: 20px;
    color: #999998;
}

.middle-link1 {
    color: #007bff;
    text-decoration: none;
    font-size: 20px;
}

.book-Showing {
    display: block;
    padding: 7px 9px;
    background-color: rgba(50, 159, 212, 0.1);
    font-size: 17px;
    font-weight: 700;
    color: #329fd4;
    border-radius: 6px;
    text-align: center;
    text-decoration: none;
    float: right;
    white-space: nowrap;
}

.book-Showing:hover {
    text-decoration: underline;
    color: #329ed4c4;
}

.content2-row-border {
    padding-top: 15px !important;
}

.Upcoming-Propertyhead {
    font-weight: 450 !important;
}

@media screen and (max-width: 550px) {
    .book-Showing {
        float: left;
        margin-top: 10px;
        padding: 5px 10px;
        font-size: 17px;
    }
    .Upcoming-Propertyhead {
        font-size: 26px !important;
    }
    .Upcoming-property {
        font-size: 14px !important;
    }
    .content2-head {
        font-size: 20px !important;
    }
    .middle-link1 {
        font-size: 18px !important;
    }
}

.content2-unit {
    color: #6c757d;
}