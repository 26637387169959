.footer {
    background: rgb(72,120,184);
background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgba(4,77,172,1) 100%);
padding-bottom: 200px;
padding-top: 100px;
position: relative;
}

.img {
    
}